import React from "react"
import Input from "src/components/common/Input/Input"
import Button from "src/components/common/Button/Button"
import "./Top2.scss"
import classNames from "classnames"

import { useTranslation } from "react-i18next"
import cn from "classnames"
import { StaticImage } from "gatsby-plugin-image"
import { checkIsAllsportsData } from "../../domain_utils"
import { checkIsAllsports } from "../../domain_utils"

function Top2({
  type,
  startRef,
  email,
  setEmail,
  transferEmail,
  platinum_amount,
  platinum_activities_amount,
  hideImage,
  country,
}) {
  const isAllsports = checkIsAllsports(country)
  const appImage = isAllsports ? (
    <StaticImage
      src="../clients/Info/images/as_app_image.png"
      alt="girl"
      placeholder="blurred"
      layout="constrained"
      width={579}
      height={350}
    />
  ) : (
    <StaticImage
      src="../clients/Info/images/sb_app_image.png"
      alt="girl"
      placeholder="blurred"
      layout="constrained"
      width={579}
      height={350}
    />
  )

  const { t } = useTranslation()
  const showAppImage = hideImage ? false : appImage

  function submit(event) {
    event.preventDefault()
    transferEmail(email)
    if (startRef.current) {
      startRef.current.scrollIntoView()
    }
  }

  const brandType = checkIsAllsportsData(country)
  const brand = brandType.brand

  return (
    <section className={classNames("top2", type)}>
      <div className="afterClass">
        <StaticImage
          src="../Top/images/girl3.png"
          alt="girl"
          placeholder="blurred"
          layout="constrained"
          width={405}
        />
      </div>
      <h1 className="top_title">{t("top2.title", { brand })}</h1>

      <section className="top-info">
        <div className="text">
          <p>{t("top2.info.item-1")}</p>
          <p>{t("top2.info.item-2", { brand })}</p>
        </div>
      </section>

      <div className="card">
        {showAppImage}
        <div className="table_amount">
          <span className={cn("big_font")}>{platinum_amount}</span>
          <span className={cn("big_font")}>{platinum_activities_amount}</span>
          <span className="small_font">{t("top2.info.item-3")}</span>
          <span className="small_font">{t("top2.info.item-4")}</span>
        </div>
      </div>

      <form onSubmit={submit}>
        <Input
          required="required"
          type="email"
          value={email}
          name="email"
          onChange={setEmail}
          placeholder="E-mail"
          autoComplete="email"
        />
        <Button color="blue">{t("top2.buttonTitle")}</Button>
      </form>
    </section>
  )
}

export default Top2
