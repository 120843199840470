import React from "react"
import "./Benefits.scss"
import { useTranslation } from "react-i18next"
import activities from "./images/Group 226.svg"
import subscription from "./images/mobile_phones.png"
import optimization from "./images/Group 222.svg"
import facilities from "./images/Group 228.svg"
import productivity from "./images/Group 385.svg"
import positive from "./images/Group 221.svg"

const benefits = {
  by: [
    {
      icon: activities,
      title: "benefits.items.item-1.title",
      text: "benefits.items.item-1.text",
    },
    {
      icon: subscription,
      title: "benefits.items.item-2.title",
      text: "benefits.items.item-2.text",
    },
    {
      icon: optimization,
      title: "benefits.items.item-3.title",
      text: "benefits.items.item-3.text",
    },
    {
      icon: facilities,
      title: "benefits.items.item-4.title",
      text: "benefits.items.item-4.text",
    },
    {
      icon: productivity,
      title: "benefits.items.item-5.title",
      text: "benefits.items.item-5.text",
    },
    {
      icon: positive,
      title: "benefits.items.item-6.title",
      text: "benefits.items.item-6.text",
    },
  ],
  am: [
    {
      icon: productivity,
      title: "benefits.items.item-5.title",
      text: "benefits.items.item-5.text",
    },
    {
      icon: subscription,
      title: "benefits.items.item-2.title",
      text: "benefits.items.item-2.text",
    },
    {
      icon: activities,
      title: "benefits.items.item-1.title",
      text: "benefits.items.item-1.text",
    },
    {
      icon: positive,
      title: "benefits.items.item-6.title",
      text: "benefits.items.item-6.text",
    },
    {
      icon: optimization,
      title: "benefits.items.item-3.title",
      text: "benefits.items.item-3.text",
    },
    {
      icon: facilities,
      title: "benefits.items.item-4.title",
      text: "benefits.items.item-4.text",
    },
  ],
  ru: [
    {
      icon: productivity,
      title: "benefits.items.item-5.title",
      text: "benefits.items.item-5.text",
    },
    {
      icon: subscription,
      title: "benefits.items.item-2.title",
      text: "benefits.items.item-2.text",
    },
    {
      icon: activities,
      title: "benefits.items.item-1.title",
      text: "benefits.items.item-1.text",
    },
    {
      icon: positive,
      title: "benefits.items.item-6.title",
      text: "benefits.items.item-6.text",
    },
    {
      icon: optimization,
      title: "benefits.items.item-3.title",
      text: "benefits.items.item-3.text",
    },
    {
      icon: facilities,
      title: "benefits.items.item-4.title",
      text: "benefits.items.item-4.text",
    },
  ],
}

function Benefits() {
  const { t } = useTranslation()

  return (
    <section className="benefits">
      <h3>{t("benefits.title")}</h3>

      <div>
        {benefits.by.map((benefit) => {
          const { icon, title, text } = benefit
          return (
            <div key={title} style={{ backgroundImage: `url('${icon}')` }}>
              <h5>{t(title)}</h5>
              <p>{t(text)}</p>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Benefits
